<template>
  <v-row class="px-10" dense>
    <v-col cols="12" md="3">
      <v-text-field
        v-model="agency.rg"
        label="RG"
        v-mask="'##.###.###-#'"
        placeholder="xx.xxx.xxx-x"
        outlined
        rounded
        dense
      />
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        v-model="agency.cpf_cnpj"
        label="CPF/CNPJ"
        v-mask="['###.###.###-##','##.###.###/####-##']"
        placeholder="xxx.xxx.xxx-xx ou xx.xxx.xxx/xxxx-xx"
        outlined
        rounded
        dense
      />
    </v-col>
    <v-col cols="12" md="3">
      <v-text-field
        v-model="agency.cnh"
        label="CNH"
        v-mask="'########-#'"
        placeholder="Digite o n° da Carteira de Habilitação"
        outlined
        rounded
        dense
      />
    </v-col>
    
  </v-row>
</template>

<script>
export default {
  props: {
    agency: Object,
  },
};
</script>